import React from "react"
import { graphql } from "gatsby"

import ServiceTemplate from "../containers/Service"

import { Seo } from "components"

const Service = ({ data }) => {
  const { service } = data

  return <ServiceTemplate service={service} />
}

export const query = graphql`
  query ($slug: String!) {
    service: contentfulServicePage(slug: { eq: $slug }) {
      id
      slug
      singlePageTitle
      singlePageDescription
      singlePageImage {
        file {
          url
        }
      }
      benefitsTitle
      benefitsSubTitle
      benefitsItems {
        step
        title
        text {
          text
        }
        image {
          file {
            url
          }
        }
      }
      detailsSubTitle
      detailsTitle
      detailsItems {
        image {
          file {
            url
          }
        }
        title
        text {
          text
        }
      }
      linksTitle
    }
  }
`

export const Head = ({ data, location }) => {
  return (
    <>
      <Seo
        title={data.service.singlePageTitle}
        description={data.service.singlePageDescription}
        ogUrl={location.pathname}
      />
    </>
  )
}

export default Service
