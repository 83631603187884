import styled from "styled-components"

export const ServiceIntro = styled.section`
  background-color: #11100f;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const ServiceTitle = styled.h1`
  font-weight: 700;
  font-size: 60px;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 45px;
    margin-bottom: 20px;
  }
`

export const ServiceText = styled.p`
  font-size: 24px;
  line-height: 1.5;
  color: #fff;
  z-index: 1;

  @media (max-width: 768px) {
    margin-bottom: 60px;
    font-size: 20px;
  }

  @media (max-width: 425px) {
    font-size: 18px;
  }
`

export const ServiceImage = styled.img`
  width: 370px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
  z-index: 0;

  @media (max-width: 1300px) {
    width: 210px;
  }

  @media (max-width: 1024px) {
    width: 160px;
    top: 87%;
    right: 20px;
  }

  @media (max-width: 425px) {
    width: 120px;
  }
`
