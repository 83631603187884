import styled from "styled-components"

export const ServiceBenefitsSubTitle = styled.p`
  font-size: 20px;
  line-height: 1.4;
  margin: 0 0 16px 0;

  @media (max-width: 768px) {
    margin: 0 0 10px 0;
  }
`

export const ServiceBenefitsGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 80px;
  row-gap: 100px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 35px;
  }

  @media (max-width: 768px) {
    row-gap: 25px;
  }
`

export const ServiceBenefitsItem = styled.li`
  display: flex;
  gap: 40px;

  @media (max-width: 768px) {
    gap: 15px;
  }
`

export const ServiceBenefitsItemImage = styled.img`
  height: 55px;
  object-fit: cover;
  margin-bottom: 14px;
  @media (max-width: 1024px) {
    height: 45px;
  }
`

export const ServiceBenefitsItemInner = styled.div``

export const ServiceBenefitsItemTitle = styled.h3`
  font-size: 22px;
  line-height: 1.2;
  font-weight: 600;
  margin: 0 0 20px 0;

  @media (max-width: 768px) {
    font-size: 18px;
    margin: 0 0 10px 0;
  }
`

export const ServiceBenefitsItemText = styled.p`
  font-size: 19px;
  line-height: 1.4;
  color: #828282;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`
