import React from "react"

import { Services } from "components"
import ServiceBenefitsSection from "./components/ServiceBenefitsSection"
import ServiceDetailsSection from "./components/ServiceDetailsSection"
import ServiceIntroSection from "./components/ServiceIntroSection"

const Service = ({ service }) => {
  const {
    singlePageTitle,
    singlePageDescription,
    singlePageImage,
    benefitsSubTitle,
    benefitsTitle,
    benefitsItems,
    detailsSubTitle,
    detailsTitle,
    detailsItems,
  } = service

  return (
    <>
      <ServiceIntroSection
        singlePageTitle={singlePageTitle}
        description={singlePageDescription}
        singlePageImage={singlePageImage}
      />

      <ServiceBenefitsSection
        benefitsSubTitle={benefitsSubTitle}
        benefitsTitle={benefitsTitle}
        benefitsItems={benefitsItems}
      />

      <ServiceDetailsSection
        detailsSubTitle={detailsSubTitle}
        detailsTitle={detailsTitle}
        detailsItems={detailsItems}
      />

      <Services services={service} page="services" />
    </>
  )
}

export default Service
