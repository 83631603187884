import styled from "styled-components"

import ServiceDeatilsArrow from "images/serviceDeatilsArrow.svg"

export const ServiceDetailsSubTitle = styled.p`
  font-size: 20px;
  line-height: 1.4;
  margin: 0 0 16px 0;
  color: #fff;
  max-width: 40%;

  @media (max-width: 768px) {
    margin: 0 0 10px 0;
    font-size: 18px;
    max-width: 100%;
  }
`

export const ServiceDetailsItems = styled.div`
  background-image: url(${ServiceDeatilsArrow});
  background-repeat: no-repeat;
  background-position: center 0px;
  background-size: contain;
  position: relative;
  padding-top: 120px;

  @media (max-width: 1300px) {
    background-size: 22% 100%;
    padding-top: 0;
  }

  @media (max-width: 1024px) {
    background: none !important;
    padding-left: 60px;
  }
`
export const ServiceDeatilsArrowMobile = styled.img`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    position: absolute;
    top: -20px;
    left: 20px;
    height: 100%;
  }
`

export const ServiceDetailsItem = styled.div`
  width: 35%;

  @media (min-width: 1025px) {
    :nth-child(2n + 1) {
      margin-left: 0;
      margin-top: 30px;
      text-align: right;
    }

    :nth-child(2n) {
      margin-left: auto;
      margin-top: 30px;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    position: relative;

    :not(:last-child) {
      margin-bottom: 40px;
    }

    :nth-child(2n + 1) {
      margin-left: 0;
      margin-top: 0;
    }

    ::before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #fff;
      left: -45px;
      top: 25px;
    }
  }
`

export const ServiceDetailsItemImage = styled.img`
  width: 75px;
  height: 75px;
  background-color: #1e1e1e;
  border-radius: 10px;
  padding: 10px;

  @media (max-width: 1024px) {
    width: 65px;
    height: 65px;
  }
`

export const ServiceDetailsItemTitle = styled.h3`
  font-size: 28px;
  line-height: 1.2;
  font-weight: 400;
  margin: 20px 0;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 22px;
    margin: 10px 0;
  }
`

export const ServiceDetailsItemText = styled.p`
  line-height: 1.6;
  color: #828282;
`
