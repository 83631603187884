import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import { FlexContainer } from "components"
import { Container } from "components/Layout/styled"
import { ServiceImage, ServiceIntro, ServiceText, ServiceTitle } from "./styled"

const ServiceIntroSection = ({
  singlePageTitle,
  description,
  singlePageImage,
}) => {
  const breakpoints = useBreakpoint()

  return (
    <ServiceIntro>
      <Container>
        <FlexContainer
          flexDirection="column"
          width={breakpoints.sm ? "100%" : breakpoints.md ? "90%" : "60%"}
        >
          <ServiceTitle>{singlePageTitle}</ServiceTitle>

          <ServiceText>{description}</ServiceText>
        </FlexContainer>

        <ServiceImage src={singlePageImage.file.url} alt={singlePageTitle} />
      </Container>
    </ServiceIntro>
  )
}

export default ServiceIntroSection
