import React from "react"

import { ServiceSection, ServiceTitle } from "../../styled"

import { Container } from "components/Layout/styled"
import ArrowMobile from "images/arrow-mobile.svg"
import ServiceDeatilsArrow from "images/serviceDeatilsArrow.svg"
import {
  ServiceDeatilsArrowMobile,
  ServiceDetailsItem,
  ServiceDetailsItemImage,
  ServiceDetailsItems,
  ServiceDetailsItemText,
  ServiceDetailsItemTitle,
  ServiceDetailsSubTitle,
} from "./styled"

const ServiceDetailsSection = ({
  detailsSubTitle,
  detailsTitle,
  detailsItems,
}) => {
  return (
    <ServiceSection dark>
      <Container>
        <ServiceDetailsSubTitle
          data-sal="slide-up"
          data-sal-duration="600"
          data-sal-delay="50"
          data-sal-easing="ease"
        >
          {detailsSubTitle}
        </ServiceDetailsSubTitle>

        <ServiceTitle
          data-sal="slide-up"
          data-sal-duration="600"
          data-sal-delay="50"
          data-sal-easing="ease"
        >
          {detailsTitle}
        </ServiceTitle>

        <ServiceDetailsItems>
          {detailsItems.map((detailsItem, index) => (
            <ServiceDetailsItem
              key={index}
              data-sal="slide-up"
              data-sal-duration="900"
              data-sal-delay="50"
              data-sal-easing="ease"
            >
              <ServiceDetailsItemImage
                src={detailsItem.image.file.url}
                alt={detailsItem.title}
              />

              <ServiceDetailsItemTitle>
                {detailsItem.title}
              </ServiceDetailsItemTitle>

              <ServiceDetailsItemText>
                {detailsItem.text.text}
              </ServiceDetailsItemText>
            </ServiceDetailsItem>
          ))}
          <ServiceDeatilsArrowMobile src={ArrowMobile} alt="ArrowMobile" />
        </ServiceDetailsItems>
      </Container>
    </ServiceSection>
  )
}

export default ServiceDetailsSection
