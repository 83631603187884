import styled from "styled-components"

export const ServiceSection = styled.section`
  padding: 10vh 0 11vh 0;
  background-color: ${props => (props.dark ? "#11100f" : "#fff")};
`

export const ServiceSubTitle = styled.p`
  font-size: 20px;
  line-height: 1.4;
  margin: 0 0 16px 0;

  @media (max-width: 768px) {
    margin: 0 0 10px 0;
  }
`

export const ServiceTitle = styled.h2`
  font-size: 52px;
  font-weight: 700;
  line-height: 1.15;
  margin: 0 0 70px 0;
  width: 75%;
  color: ${props => (props.dark ? "#000" : "#fff")};

  @media (max-width: 1300px) {
    font-size: 42px;
    width: 100%;
  }

  @media (max-width: 1024px) {
    margin: 0 0 45px 0;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin: 0 0 30px 0;
  }
`

export const ServiceText = styled.p`
  font-size: 19px;
  line-height: 1.4;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`
