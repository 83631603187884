import React from "react"

import { ServiceSection, ServiceTitle } from "../../styled"

import { Container } from "components/Layout/styled"
import {
  ServiceBenefitsGrid,
  ServiceBenefitsItem,
  ServiceBenefitsItemImage,
  ServiceBenefitsItemInner,
  ServiceBenefitsItemText,
  ServiceBenefitsItemTitle,
  ServiceBenefitsSubTitle,
} from "./styled"

const ServiceBenefitsSection = ({
  benefitsSubTitle,
  benefitsTitle,
  benefitsItems,
}) => {
  return (
    <ServiceSection>
      <Container>
        <ServiceBenefitsSubTitle
          data-sal="slide-up"
          data-sal-duration="600"
          data-sal-delay="50"
          data-sal-easing="ease"
        >
          {benefitsSubTitle}
        </ServiceBenefitsSubTitle>

        <ServiceTitle
          dark
          data-sal="slide-up"
          data-sal-duration="600"
          data-sal-delay="50"
          data-sal-easing="ease"
        >
          {benefitsTitle}
        </ServiceTitle>

        <ServiceBenefitsGrid>
          {benefitsItems.map((benefitsItem, index) => (
            <ServiceBenefitsItem
              key={index}
              data-sal="slide-up"
              data-sal-duration="600"
              data-sal-delay="50"
              data-sal-easing="ease"
            >
              <ServiceBenefitsItemInner>
                <ServiceBenefitsItemImage
                  src={benefitsItem.image.file.url}
                  alt={benefitsItem.title}
                />

                <ServiceBenefitsItemTitle>
                  {benefitsItem.title}
                </ServiceBenefitsItemTitle>

                <ServiceBenefitsItemText>
                  {benefitsItem.text.text}
                </ServiceBenefitsItemText>
              </ServiceBenefitsItemInner>
            </ServiceBenefitsItem>
          ))}
        </ServiceBenefitsGrid>
      </Container>
    </ServiceSection>
  )
}

export default ServiceBenefitsSection
